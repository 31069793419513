import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory } from "react-router-dom"
import logoMy from "./logoMy.png"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row, CardImg, CardBody, Card } from "reactstrap"
import logo from "../../components/VerticalLayout/logo.png"
import im1 from "./im1.png"
import { ToastContainer, toast } from "react-toastify"
// import images
import backend from "../../api/api"
import { Grid } from "@mui/material"
import "./login.css"
import { loginUser } from "store/actions"
const Login2 = () => {
  const [state, setState] = useState({ email: "", password: "" })
  const dispatch = useDispatch()
  const history = useHistory()

  const handleChange = e => {
    const { name, value } = e.target

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const postLogin = async () => {
    try {
      if (!state.email || state.email == "") {
        toast.error("Email is required")
      }
      if (!state.password || state.password == "") {
        toast.error("password is required")
      } else {
        const body = {
          email: state.email,
          password: state.password,
        }
        const resp = await backend.post("/api/user/login", body)
        localStorage.setItem("userToken", resp.data.token)
        dispatch(loginUser(resp?.data, history, toast))
        dispatch({
          type: "LOGIN_USER",
          payload: resp.data.doc,
        })
        toast.success("Login Successfully")
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message || error)
    }
  }
  return (
    <React.Fragment>
      <div className="account-pages">
        <MetaTags>
          <title>Login | Trip Trader</title>
        </MetaTags>

        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <img src={im1} className="loginImg" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className="logincontainerResponsive"
          >
            <div className="account-pages my-5 loginformContainer">
              <Container>
                <Row className="justify-content-center">
                  <Card className="overflow-hidden">
                    <div
                      style={{
                        backgroundColor: "#3c6b49",
                      }}
                    >
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          Welcome Back !
                        </h5>
                        <p className="text-white">
                          Sign in to continue to Trip Trader.
                        </p>
                        <Link to="/" className="logo logo-admin">
                          <img src={logoMy} height="60" alt="logo" />
                        </Link>
                      </div>
                    </div>
                    <CardBody className="p-4">
                      <div className="p-3">
                        <AvForm className="form-horizontal mt-4">
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="Email"
                              value={state.email}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="Enter Email"
                              type="text"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Password"
                              value={state.password}
                              type="password"
                              placeholder="Enter password"
                              // name="password"
                              onChange={handleChange}
                            />
                          </div>

                          <Row className="mb-3">
                            <button
                              className="btn w-md waves-effect waves-light"
                              style={{
                                backgroundColor: "#3c6b49",
                                color: "white",
                              }}
                              type="submit"
                              onClick={postLogin}
                            >
                              Log In
                            </button>
                            <ToastContainer />
                          </Row>
                          <Row className="mt-2 mb-0 row">
                            <div className="col-12 mt-4">
                              <Link to="/forgotpassword">
                                <i
                                  className="mdi mdi-lock"
                                  style={{
                                    color: "#3c6b49",
                                  }}
                                ></i>
                                <span
                                  style={{
                                    color: "#3c6b49",
                                  }}
                                >
                                  Forgot your password?
                                </span>
                              </Link>
                            </div>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
              </Container>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default Login2
