const initialState = { review: [] }

export default function ReviewReducer(state = initialState, action) {
  switch (action.type) {
    case "REVIEW_SUCCESS":
      return {
        success: true,
        review: action.payload,
      }

    default:
      return state
  }
}
