import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError } from "./actions"
import { toast, ToastContainer } from "react-toastify"

function* loginUser({ payload: { user, history, toast } }) {
  try {
    toast.success(user.message)
    history.push("/triptrader/dashboard")
  } catch (error) {
    toast?.error(error?.response?.data?.message || error?.message || error)
  }
}

function* logoutUser({ payload: { history, toast } }) {
  try {
    localStorage.removeItem("userToken")
    history.push("/")
    toast?.success("Log Out Successfully")
  } catch (error) {
    toast?.error(error?.response?.data.message || error?.message || error)
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
