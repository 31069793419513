const initialState = { stats: [] }

export default function CustomerStatsReducer(state = initialState, action) {
  switch (action.type) {
    case "CUSTOMER_STATS":
      return {
        success: true,
        stats: action.payload,
      }

    default:
      return state
  }
}
