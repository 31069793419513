const initialState = { trip: [] }

export default function TripReducer(state = initialState, action) {
  switch (action.type) {
    case "TRIP_SUCCESS":
      return {
        success: true,
        trip: action.payload,
      }

    default:
      return state
  }
}
