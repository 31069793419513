import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

import Profile from "./auth/profile/reducer"

//Calendar
import GetUserReducer from "./getUser/getUserReducer"
import TripReducer from "./trip/tripReducer"
import TraderTypeReducer from "./traderTypeReducer/traderTypeReducer"
import SpeciesReducers from "./SpeciesReducer/SpeciesReducers"
import SubscriptionReducer from "./SubscriptionReducer/subscriptionReducer"
import PromoCodeReducer from "./promoCodeReducer/promoCodeReducer"
import SettingReducer from "./settingReducer/settingReducer"
import ReviewReducer from "./reviewReducer/reviewReducer"
import UserAuthReducer from "./UserAuth/UserAuthReducer"
import StateReducer from "./stateReducer/stateReducer"
import TripStatsReducer from "./tripsStats/tripStatsReducer"
import CustomerStatsReducer from "./CustomerStats/CustomerStatsReducer"
import ReportReducer from "./reportReducer/reportReducer"
import CategoryReducer from "./CatagoryReducer/CatagoryReducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Profile,
  GetUserReducer,
  TripReducer,
  TraderTypeReducer,
  SpeciesReducers,
  SubscriptionReducer,
  PromoCodeReducer,
  SettingReducer,
  ReviewReducer,
  UserAuthReducer,
  StateReducer,
  CustomerStatsReducer,
  TripStatsReducer,
  ReportReducer,
  CategoryReducer,
})

export default rootReducer
