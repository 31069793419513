import React, { useState } from "react"
import { Button, Col, Container, Row } from "reactstrap"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import backend from "api/api"
import { CircularProgress } from "@mui/material"
export default function NewAdmin() {
  const [state, setState] = useState({ email: "", password: "" })
  const [adminImg, setAdminImg] = useState()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const handleChange = e => {
    const { name, value } = e.target

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const adminImages = async e => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append("files", file)
    await UploadImage(formData)
  }
  const UploadImage = async images => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data;`,
        },
      }
      setLoading(true)
      const resp = await backend.post("/api/upload", images, config)
      setLoading(false)
      setAdminImg(resp.data.data)
      toast.success(resp?.data?.message)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }
  const loginAdmin = async () => {
    try {
      if (!state.email || state.email == "") {
        toast.error("Email is required")
      } else if (!state.password || state.password == "") {
        toast.error("password is required")
      } else if (!adminImg || adminImg == "") {
        toast.error("Profile Image is required")
      } else {
        const body = {
          email: state.email,
          password: state.password,
          role: "admin",
          image: adminImg[0].imgrUrl,
        }
        const resp = await backend.post("/api/user", body)
        toast.success(resp?.data?.message)
        setTimeout(() => history.push("/triptrader/admin"))
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>New Admin | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Admins"
                  breadcrumbItem="New Admin"
                />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>

              <Col md="4"></Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <label
                htmlFor="example-text-input"
                className="col-md-12 col-form-label"
              >
                Email
              </label>
              <input
                className="form-control"
                type="text"
                value={state.email}
                name="email"
                onChange={handleChange}
                placeholder="Enter Email"
              />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <label
                htmlFor="example-text-input"
                className="col-md-12 col-form-label"
              >
                Password
              </label>
              <input
                className="form-control"
                type="password"
                value={state.password}
                placeholder="Enter a Password"
                onChange={handleChange}
                name="password"
              />
            </Col>
            <Col xs={12} sm={12} md={6} className="mt-3">
              <div class="input-group mb-3">
                <input
                  type="file"
                  class="form-control"
                  id="inputGroupFile02"
                  onChange={adminImages}
                  name="image"
                />
              </div>
              {loading ? <CircularProgress size={10} /> : ""}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={4} sm={3} md={1}>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                onClick={loginAdmin}
              >
                Save
              </Button>{" "}
              <ToastContainer />
            </Col>
            <Col xs={3} sm={3} md={6}>
              <Link
                to="/triptrader/admin"
                color="secondary"
                className="btn btn-secondary waves-effect"
              >
                Cancel
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
