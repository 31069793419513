import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState } from "react"
import { Row, Card, CardBody, Container } from "reactstrap"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import fg1 from "./fg1.png"
import logoMy from "./logoMy.png"
import { Grid } from "@mui/material"
import { toast, ToastContainer } from "react-toastify"
import backend from "api/api"

const ForgetPasswordPage = props => {
  const [email, setEmail] = useState()
  const postForgotPassword = async () => {
    try {
      const body = {
        email: email,
      }
      if (!email || email == "") {
        toast.error("Email is required")
      } else {
        const resp = await backend.put("/api/user/password/forgot", body)
        toast.success(resp.data.message)
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message || error)
    }
  }
  return (
    <React.Fragment>
      <div className="account-pages">
        <MetaTags>
          <title>Forgot Password | Trip Trader</title>
        </MetaTags>

        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <img src={fg1} className="loginImg" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className="logincontainerResponsive"
          >
            <div className="account-pages my-5 loginformContainer">
              <Container>
                <Row className="justify-content-center">
                  <Card className="overflow-hidden">
                    <div
                      style={{
                        backgroundColor: "#3c6b49",
                      }}
                    >
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          Forget Password !
                        </h5>
                        <p className="text-white"></p>
                        <Link to="/" className="logo logo-admin">
                          <img src={logoMy} height="60" alt="logo" />
                        </Link>
                      </div>
                    </div>
                    <CardBody className="p-4">
                      <div className="p-3">
                        <AvForm className="form-horizontal mt-4">
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="Email"
                              onChange={e => setEmail(e.target.value)}
                              className="form-control"
                              placeholder="Enter Email"
                              type="text"
                            />
                          </div>

                          <Row className="mb-3">
                            <button
                              className="btn w-md waves-effect waves-light"
                              style={{
                                backgroundColor: "#3c6b49",
                                color: "white",
                              }}
                              type="submit"
                              onClick={postForgotPassword}
                            >
                              Reset
                            </button>
                            <ToastContainer />
                          </Row>
                        </AvForm>
                        <div className="mt-5 text-center">
                          <p>
                            Remember It ?{" "}
                            <Link to="/" className="fw-medium text-primary">
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                          <p>© {new Date().getFullYear()} Trip Trader</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
              </Container>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  // userForgetPassword: PropTypes.func,
}

const mapStatetoProps = state => {}
export default withRouter(connect(mapStatetoProps, {})(ForgetPasswordPage))
