import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Col, Row, Container, Button } from "reactstrap"
import backend from "api/api"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { useTheme } from "@mui/material/styles"

import { toast, ToastContainer } from "react-toastify"
import { useEffect } from "react"
import { TextField } from "@mui/material"

export default function NewState() {
  const history = useHistory()
  const dispatch = useDispatch()

  const [state, setState] = useState({
    name: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const postState = async () => {
    try {
      const body = {
        name: state.name,
      }
      if (!state.name || state.name == "") {
        toast.error("name is required")
      } else {
        const resp = await backend.post("/api/state", body)
        toast.success(resp?.data?.message)
        setTimeout(() => history.push("/triptrader/state"), 1000)
      }
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message || error)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>New State| Trip Traders</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="State"
                  breadcrumbItem="New State"
                />
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                >
                  State Name
                </label>
                <input
                  className="form-control"
                  style={{
                    width: "100%",
                  }}
                  name="name"
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row
              style={{
                marginTop: "1rem",
              }}
            >
              <Col xs={4} sm={3} md={1}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  onClick={postState}
                >
                  Save
                </Button>{" "}
                <ToastContainer />
              </Col>
              <Col xs={3} sm={3} md={6}>
                <Link
                  to="/triptrader/state"
                  color="secondary"
                  className="btn btn-secondary waves-effect"
                >
                  Cancel
                </Link>{" "}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
