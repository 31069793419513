const initialState = { login: {} }

export default function UserAuthReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        success: true,
        login: action.payload,
      }
    case "LOGOUT_SUCCESS":
      return {
        success: false,
        login: {},
      }

    default:
      return state
  }
}
