const initialState = { code: [] }

export default function PromoCodeReducer(state = initialState, action) {
  switch (action.type) {
    case "PROMO_CODE_SUCCESS":
      return { success: true, code: action.payload }
    default:
      return state
  }
}
