const initialState = { species: [] }

export default function SpeciesReducers(state = initialState, action) {
  switch (action.type) {
    case "SPECIES_SUCCESS":
      return {
        success: true,
        species: action.payload,
      }

    default:
      return state
  }
}
