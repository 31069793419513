import backend from "api/api"
import Breadcrumbs from "components/Common/Breadcrumb"
import SearchTable from "components/SearchTable/searchTable"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import Switch from "react-switch"
import servicesIcon1 from "assets/images/services-icon/01.png"
import servicesIcon2 from "assets/images/services-icon/02.png"
import servicesIcon3 from "assets/images/services-icon/03.png"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import { CircularProgress, Tooltip } from "@mui/material"
export default function Subcription() {
  const dispatch = useDispatch()
  let rows = []
  const [loading, setLoading] = useState(false)

  const { subscribe } = useSelector(state => state?.SubscriptionReducer)
  const activeSubscribe = subscribe?.data?.filter(e => e?.active === true)
  const disabledSubscribe = subscribe?.data?.filter(e => e?.active === false)
  console.log("subscribe", subscribe?.data)
  const getAllSubscription = async () => {
    try {
      setLoading(true)
      const resp = await backend.get("/api/plan/allplan")
      setLoading(false)
      dispatch({
        type: "SUBSCRIBE_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message | error)
    }
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }
  const changeSpeciesStatus = async (status, id) => {
    try {
      console.log("123", status)
      const value = status.props.children === "Active" ? false : true
      const body = {
        active: value,
      }
      const resp = await backend.patch(`/api/plan/updateplan/${id}`, body)
      getAllSubscription()
      toast.success(resp.data.message)
    } catch (error) {
      toast.error(
        error?.raw?.message ||
          error?.response?.data.message ||
          error?.message ||
          error
      )
    }
  }
  const handleChangeStatus = params => {
    return (
      <>
        <Switch
          uncheckedIcon={<Offsymbol />}
          checkedIcon={<OnSymbol />}
          onColor="#02a499"
          onChange={() =>
            changeSpeciesStatus(params.status, params.subcriptionId)
          }
          checked={params?.status?.props?.children === "Active" ? true : false}
        />
        <ToastContainer />
      </>
    )
  }
  const myAction = params => {
    return (
      <>
        {/* <Link to={`/triptrader/subscriptionedit/${params.subcriptionId}`}>
          <Tooltip title="Edit Subscriptions">
            <EditLocationAltIcon />
          </Tooltip>
        </Link> */}

        <Link to={`/triptrader/subscriptiondetail/${params.subcriptionId}`}>
          <Tooltip title="View Subscriptions">
            <VisibilityOutlinedIcon />
          </Tooltip>
        </Link>
      </>
    )
  }
  const columns = [
    {
      title: "Sr. No#",
      field: "srNo",
    },
    {
      title: "Subscription Type",
      field: "subscriptionType",
    },
    {
      title: "Charges",
      field: "charges",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Change Status",
      field: "changeStatus",
      render: handleChangeStatus,
    },
    {
      title: "Action",
      field: "action",
      render: myAction,
    },
  ]

  if (subscribe) {
    subscribe?.data?.map((e, i) => {
      rows.push({
        srNo: i + 1,
        subcriptionId: e?.id,
        subscriptionType: (
          <p
            style={{
              textTransform: "capitalize",
            }}
          >
            {e?.interval}
          </p>
        ),
        charges: e?.amount,
        // save: <span>{e?.discount}$</span>,
        status:
          e?.active === true ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Disable</span>
          ),
      })
    })
  }

  useEffect(() => {
    getAllSubscription()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Subscription | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Subscription"
                  breadcrumbItem="Subscription"
                />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0">
                      Total Subscription
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {subscribe?.data?.length}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat  text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0 ">
                      Active Subscription
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {activeSubscribe?.length}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat  text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h4 className="font-size-14 text-uppercase mt-0 ">
                      InActive Subscription
                    </h4>
                    <h4 className="fw-medium font-size-24">
                      {disabledSubscribe?.length}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {loading ? (
            <CircularProgress />
          ) : (
            <SearchTable columns={columns} data={rows} />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
