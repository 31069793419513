const initialState = { traderType: [] }

export default function TraderTypeReducer(state = initialState, action) {
  switch (action.type) {
    case "TradeType_SUCCESS":
      return {
        success: true,
        traderType: action.payload,
      }

    default:
      return state
  }
}
