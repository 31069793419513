const initialState = { state: [] }

export default function StateReducer(state = initialState, action) {
  switch (action.type) {
    case "STATE_SUCCESS":
      return {
        success: true,
        state: action.payload,
      }
    default:
      return state
  }
}
