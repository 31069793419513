import React from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody } from "reactstrap"
import bg from "./bgImage.png"

export default function NoFoundpage() {
  return (
    <>
      <img
        src={bg}
        style={{
          height: "100vh",
          width: "100%",
          position: "absolute",
        }}
      />
      <div
        className="NoFoundPage"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            position: "relative",
          }}
          className="PageTitle"
        >
          <h2
            style={{
              color: "yellowgreen",
            }}
          >
            PAGE NOT FOUND
          </h2>
          <Link
            to="/"
            style={{
              textAlign: "center",
              fontWeight: 500,
              margin: "1rem 60px",
            }}
            color="secondary"
            className="btn btn-secondary waves-effect"
          >
            Redirect
          </Link>
        </div>
      </div>
    </>
  )
}
