import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import backend from "api/api"
import { toast, ToastContainer } from "react-toastify"
import { Container, Row, Col, Button } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useParams, Link, useHistory } from "react-router-dom"
export default function SpeciesEdit() {
  let { id } = useParams()
  let history = useHistory()
  const dispatch = useDispatch()
  const { species } = useSelector(state => state.SpeciesReducers)
  const editSpecies = species.find(e => e?._id === id)
  const [editTheSpecies, setEditTheSpecies] = useState(editSpecies)
  const { Category } = useSelector(state => state?.CategoryReducer)

  const { traderType } = useSelector(state => state.TraderTypeReducer)
  const [speciesTradeType, setSpeciesTradeType] = useState(
    editSpecies?.type?.name
  )
  const [speciesCategory, setSpeciesCategory] = useState(
    editSpecies?.category?.name
  )
  const handleTradeType = e => {
    setSpeciesTradeType(e.target.value)
  }
  const handleCategory = e => {
    setSpeciesCategory(e.target.value)
  }
  const getAllTradeType = async () => {
    try {
      const resp = await backend.get("/api/type")
      dispatch({
        type: "TradeType_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message || error)
    }
  }
  const getAllCategory = async () => {
    try {
      const resp = await backend.get("/api/category")
      console.log(resp)
      dispatch({
        type: "CATEGORY_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }
  const handleTheActivity = async () => {
    try {
      const body = {
        name: editTheSpecies?.name,
        type: traderType.find(e => e?.name === speciesTradeType)?._id,
        category: Category?.find(e => e?.name === speciesCategory)?._id,
      }
      const resp = await backend.put(`api/species/${id}`, body)
      toast.success(resp?.data?.message)
      setTimeout(() => history.push("/triptrader/species"), 1000)
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message || error)
    }
  }
  const getAllSpecies = async () => {
    try {
      const resp = await backend.get("/api/species")
      dispatch({
        type: "SPECIES_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }
  useEffect(() => {
    getAllSpecies()
    getAllTradeType()
    getAllCategory()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Species Edit | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                {/* <h6 className="page-title WebHeader">Admins</h6> */}
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Species"
                  breadcrumbItem="Species Edit"
                />

                <ol className="breadcrumb m-0">
                  <li className="lbreadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                >
                  Species Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={editTheSpecies.name}
                  onChange={e =>
                    setEditTheSpecies({
                      ...editTheSpecies,
                      name: e.target.value,
                    })
                  }
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label className="col-md-12 col-form-label">Trader Type</label>

                <select
                  className="form-select"
                  name="TradeType"
                  value={speciesTradeType}
                  onChange={handleTradeType}
                >
                  {traderType?.map(e => (
                    <>
                      <option value={e?.name}>{e?.name}</option>
                    </>
                  ))}
                </select>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label className="col-md-12 col-form-label">Categories</label>

                <select
                  className="form-select"
                  name="Category"
                  value={speciesCategory}
                  onChange={handleCategory}
                >
                  {Category?.map(e => (
                    <>
                      <option value={e?.name}>{e?.name}</option>
                    </>
                  ))}
                </select>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={4} sm={3} md={1}>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                onClick={handleTheActivity}
              >
                Save
              </Button>{" "}
              <ToastContainer />
            </Col>
            <Col xs={3} sm={3} md={6}>
              <Link
                to="/triptrader/species"
                color="secondary"
                className="btn btn-secondary waves-effect"
              >
                Cancel
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
