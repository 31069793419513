import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Col, Row, Container, Card, CardBody } from "reactstrap"
import Switch from "react-switch"
import backend from "api/api"
import SearchTable from "components/SearchTable/searchTable"
import { useDispatch, useSelector } from "react-redux"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { Link } from "react-router-dom"
import servicesIcon1 from "assets/images/services-icon/01.png"
import servicesIcon2 from "assets/images/services-icon/02.png"
import servicesIcon3 from "assets/images/services-icon/03.png"
import { toast, ToastContainer } from "react-toastify"
import { CircularProgress, Tooltip } from "@mui/material"

export default function State() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  let rows = []
  const { state } = useSelector(state => state.StateReducer)
  const activeState = state?.filter(e => e?.status == "active")
  const inActiveState = state?.filter(e => e?.status == "disabled")
  const getAllStates = async () => {
    try {
      setLoading(true)
      const resp = await backend.get("/api/state")
      setLoading(false)
      dispatch({
        type: "STATE_SUCCESS",
        payload: resp?.data?.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }
  const myAction = params => {
    return (
      <>
        <Link to={`/triptrader/stateedit/${params.statusId}`}>
          <Tooltip title="Edit States">
            <EditLocationAltIcon />
          </Tooltip>
        </Link>

        <Link to={`/triptrader/statedetail/${params.statusId}`}>
          <Tooltip title="View States">
            <VisibilityOutlinedIcon />
          </Tooltip>
        </Link>
      </>
    )
  }
  const changeStateStatus = async (status, id) => {
    try {
      const value = status.props.children === "Active" ? "disabled" : "active"
      const body = {
        status: value,
      }
      const resp = await backend.put(`/api/state/${id}`, body)
      resp
      getAllStates()
      toast.success(resp?.data?.message)
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message || error)
    }
  }
  const handleChangeStatus = params => {
    return (
      <>
        <Switch
          uncheckedIcon={<Offsymbol />}
          checkedIcon={<OnSymbol />}
          onColor="#02a499"
          onChange={() => changeStateStatus(params.status, params.statusId)}
          checked={params?.status.props.children === "Active" ? true : false}
        />

        <ToastContainer />
      </>
    )
  }

  const columns = [
    { title: "Sr No#", field: "srNo" },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Change Status",
      field: "changeStatus",
      render: handleChangeStatus,
    },
    {
      title: "Action",
      field: "action",
      render: myAction,
    },
  ]
  if (state) {
    state.map((e, i) =>
      rows.push({
        srNo: i + 1,
        statusId: e?._id,
        name: (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {e?.name}
          </span>
        ),
        status:
          e?.status === "active" ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Disable</span>
          ),
      })
    )
  }
  useEffect(() => {
    getAllStates()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>State | Trip Traders</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="State"
                  breadcrumbItem="State"
                />
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0">
                      Total State
                    </h5>
                    <h4 className="fw-medium font-size-24">{state?.length}</h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat  text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0 ">
                      Active State
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {activeState?.length}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat  text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h4 className="font-size-14 text-uppercase mt-0 ">
                      InActive States
                    </h4>
                    <h4 className="fw-medium font-size-24">
                      {inActiveState?.length}
                      {/* <i className="mdi mdi-arrow-up text-success ms-2"></i> */}
                    </h4>
                    {/* <div className="mini-stat-label bg-info">
                        <p className="mb-0"> 00%</p>
                      </div> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {loading ? (
            <CircularProgress />
          ) : (
            <SearchTable columns={columns} data={rows} />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
