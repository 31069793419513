import axios from "axios"
const backend = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BACKEND_DEV
      : process.env.REACT_APP_BACKEND_LOCAL,
})
backend.interceptors.request.use(
  config => {
    let token = localStorage.getItem("userToken")
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
    return config
  },
  error => Promise.reject(error)
)
export default backend
