import { CircularProgress, Tooltip } from "@mui/material"

import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import "./User.css"
import Switch from "react-switch"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import "chartist/dist/scss/chartist.scss"
import SearchTable from "../../components/SearchTable/searchTable"
import { useDispatch, useSelector } from "react-redux"
import backend from "api/api"
import { toast, ToastContainer } from "react-toastify"
export default function ReportedCustomers() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  let rows = []
  const { reported } = useSelector(state => state.ReportReducer)
  const activeUser = reported.filter(e => e?.status === "active")
  const resolvedUser = reported.filter(e => e?.status === "resolved")
  console.log(reported)
  const getReportedtUsers = async () => {
    try {
      setLoading(true)
      const resp = await backend.get("/api/report")
      setLoading(false)
      dispatch({
        type: "REPORTEDUSER_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      setLoading(false)
      toast.error(error.response?.data.message || error?.message || error)
    }
  }
  const myAction = params => {
    return (
      <>
        <Link to={`/triptrader/reportedcustomers/${params.reportedId}`}>
          <Tooltip title="View Reported Customer">
            <VisibilityOutlinedIcon />
          </Tooltip>
        </Link>
      </>
    )
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }
  const changeReportedStatus = async (status, id) => {
    try {
      const value = status.props.children === "Active" ? "resolved" : "active"
      const body = {
        status: value,
      }
      const resp = await backend.put(`/api/report/${id}`, body)
      getReportedtUsers()
      toast.success(resp?.data?.message)
    } catch (error) {
      toast.error(error?.response?.data.message || error.message || error)
    }
  }
  const handleChangeStatus = params => {
    return (
      <>
        <Switch
          uncheckedIcon={<Offsymbol />}
          checkedIcon={<OnSymbol />}
          onColor="#02a499"
          onChange={() =>
            changeReportedStatus(params.status, params.reportedId)
          }
          checked={params?.status.props.children === "Active" ? true : false}
        />
        <ToastContainer />
      </>
    )
  }

  const columnhead = [
    { title: "Cust. #", field: "Srno" },
    {
      title: "Full Name",
      field: "userName",
    },
    { title: "Email", field: "userEmail" },

    { title: "Reported Status", field: "status" },
    {
      title: "Change Status",
      field: "changeStatus",
      render: handleChangeStatus,
    },
    {
      title: "Action",
      field: "Action",
      render: myAction,
    },
  ]

  if (reported) {
    reported?.map((e, i) => {
      rows.push({
        Srno: i + 1,
        reportedId: e?._id,
        userName: (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {e?.userId?.firstName + " " + e?.userId?.lastName}
          </span>
        ),
        userEmail: e?.userId?.email,
        status:
          e?.status == "active" ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Resolved</span>
          ),
      })
    })
  }

  useEffect(() => {
    getReportedtUsers()
  }, [])

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Reported Customer| Trip Traders</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col md={8}>
                  <Breadcrumbs
                    maintitle="Trip Trader"
                    title="Customers"
                    breadcrumbItem="Reported Customers"
                  />

                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item active"></li>
                  </ol>
                </Col>
              </Row>
            </div>
            <Row>
              <Col xl={4} md={6}>
                <Card
                  className="mini-stat text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon1} alt="" />
                      </div>
                      <h5 className="font-size-14 text-uppercase mt-0">
                        Reported Customers
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {reported.length}{" "}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4} md={6}>
                <Card
                  className="mini-stat text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon2} alt="" />
                      </div>
                      <h5 className="font-size-14 text-uppercase mt-0">
                        Active Customers
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {activeUser.length}{" "}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4} md={6}>
                <Card
                  className="mini-stat text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon3} alt="" />
                      </div>
                      <h5 className="font-size-15 text-uppercase mt-0">
                        Resolved Customers
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {resolvedUser.length}{" "}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {loading ? (
              <CircularProgress />
            ) : reported.length > 0 ? (
              <SearchTable columns={columnhead} data={rows} />
            ) : (
              <h1>No Record Found</h1>
            )}
            <ToastContainer />
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}
