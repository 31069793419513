import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Col, Row, Container, Card, CardBody } from "reactstrap"
import backend from "api/api"
import SearchTable from "components/SearchTable/searchTable"
import { useDispatch, useSelector } from "react-redux"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { Link } from "react-router-dom"
import { CircularProgress, Tooltip } from "@mui/material"
import servicesIcon1 from "assets/images/services-icon/01.png"
import servicesIcon2 from "assets/images/services-icon/02.png"
import servicesIcon3 from "assets/images/services-icon/03.png"
import servicesIcon4 from "assets/images/services-icon/04.png"
import capture from "./capture.png"
import Not from "./Not.png"
import { toast, ToastContainer } from "react-toastify"

export default function Review() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  let rows = []
  const getAllReview = async () => {
    try {
      setLoading(true)
      const resp = await backend.get("/api/review")
      setLoading(false)
      dispatch({
        type: "REVIEW_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message || error)
    }
  }
  const { review } = useSelector(state => state.ReviewReducer)
  const ActiveReview = review.filter(e => e?.status === "active")
  const notGivenReview = review.filter(e => e?.status === "notGiven")
  const approvedReview = review.filter(e => e?.status === "approved")
  const rejectedReview = review.filter(e => e?.status === "rejected")
  const disputeReview = review.filter(e => e?.status === "dispute")
  if (review) {
    review.map((e, i) =>
      rows.push({
        reviewId: e?._id,
        srNo: i + 1,
        guestName: e?.guestId?.firstName,
        hostName: e?.hostId?.firstName,
        status:
          e?.status === "notGiven" ? (
            <span className="badge bg-warning">Not Given</span>
          ) : e?.status === "active" || e?.status === "approved" ? (
            <span
              style={{
                textTransform: "capitalize",
              }}
              className="badge bg-success"
            >
              {e?.status}
            </span>
          ) : (
            <span
              style={{
                textTransform: "capitalize",
              }}
              className="badge bg-danger"
            >
              {e?.status}
            </span>
          ),
      })
    )
  }
  const myAction = params => {
    return (
      <>
        <Link to={`/triptrader/reviewedit/${params.reviewId}`}>
          <Tooltip title="Edit Reviews">
            <EditLocationAltIcon />
          </Tooltip>
        </Link>

        <Link to={`/triptrader/reviewdetail/${params.reviewId}`}>
          <Tooltip title="View Reviews">
            <VisibilityOutlinedIcon />
          </Tooltip>
        </Link>
      </>
    )
  }
  const columns = [
    {
      title: "Sr .No",
      field: "srNo",
    },
    {
      title: "Guest Name",
      field: "guestName",
    },
    {
      title: "Host Name",
      field: "hostName",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Action",
      field: "Action",
      render: myAction,
    },
  ]

  useEffect(() => {
    getAllReview()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Manage Review| Trip Traders</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Manage Review"
                  breadcrumbItem="Manage Review"
                />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0">
                      Total Reviews
                    </h5>
                    <h4 className="fw-medium font-size-24">{review.length} </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0">
                      Active Reviews
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {ActiveReview?.length}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h5 className="font-size-15 text-uppercase mt-0">
                      Approved Reviews
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {approvedReview.length}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0 ">
                      Rejected Reviews
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {rejectedReview.length}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={capture} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0 ">
                      Dispute Reviews
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {disputeReview.length}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={Not} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0 ">
                      NOTGIVEN Reviews
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {notGivenReview.length}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {loading ? (
            <CircularProgress />
          ) : (
            <SearchTable columns={columns} data={rows} />
          )}
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}
