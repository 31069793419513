import PropTypes from "prop-types"
import React, { useEffect } from "react"
import "react-toastify/dist/ReactToastify.css"
import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import { connect } from "react-redux"
import { userRoutes, authRoutes } from "./routes/allRoutes"
import backend from "api/api"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import { useSelector, useDispatch } from "react-redux"
import { updateAuth, loginUser } from "store/actions"
import { toast } from "react-toastify"

const App = props => {
  const { userLogin, authtication } = useSelector(state => state.Login)
  const { login } = useSelector(state => state.UserAuthReducer)
  const dispatch = useDispatch()
  useEffect(() => {
    const authtication =
      localStorage.getItem("userToken") && Object.keys(login).length
        ? true
        : false
    dispatch(updateAuth(authtication))
  }, [])

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = VerticalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  const theme = {}
  const getAllSetting = async () => {
    try {
      const resp = await backend.put("/api/setting/6331909d9a4f528f1b4cc616")
      dispatch({
        type: "SETTING_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message || error)
    }
  }
  useEffect(() => {
    if (authtication) {
      getAllSetting()
    }
  }, [])
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authtication
            ? userRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))
            : authRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))}
        </Switch>
      </Router>
    </React.Fragment>
    // </ThemeProvider>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
