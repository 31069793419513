import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { useSelector } from "react-redux"
import backend from "api/api"
import { Container, Row, Col, Button } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useParams, Link, useHistory } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
export default function SubscriptionEdit() {
  let { id } = useParams()
  let history = useHistory()
  const { subscribe } = useSelector(state => state?.SubscriptionReducer)
  const EditSubscribe = subscribe?.data?.find(e => e?.id === id)
  const [editTheSubscribe, setEditTheSubscribe] = useState(EditSubscribe)
  const [subscriptionType, setSubscriptionType] = useState(
    editTheSubscribe?.interval
  )
  const updateSubscription = async () => {
    try {
      const body = {
        type: subscriptionType,
        charges: editTheSubscribe?.amount,
      }
      const resp = await backend.put(`/api/updateplan/${id}`, body)
      toast.success(resp.data.message)
      setTimeout(() => history.push("/triptrader/subscription"), 1000)
    } catch (error) {
      toast.error(error?.response?.data.message || error?.message || error)
    }
  }
  const handleSubscriptionType = e => {
    setSubscriptionType(e.target.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Subscription Edit | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                {/* <h6 className="page-title WebHeader">Admins</h6> */}
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Subscription"
                  breadcrumbItem="Subscription Edit"
                />
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row className="mb-3">
            <Col xs={12} sm={12} md={6}>
              <label className="col-md-12 col-form-label">Type</label>

              <select
                className="form-select"
                value={subscriptionType}
                onChange={handleSubscriptionType}
              >
                {/* <option value="weekly">Weekly</option> */}
                <option value="monthly">Monthly</option>
                <option value="annual">Annual</option>
              </select>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <label
                htmlFor="example-text-input"
                className="col-md-12 col-form-label"
              >
                Charges
              </label>

              <input
                className="form-control"
                type="email"
                onChange={e =>
                  setEditTheSubscribe({
                    ...editTheSubscribe,
                    charges: e.target.value,
                  })
                }
                value={editTheSubscribe?.amount}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12} sm={12} md={6}>
              <label
                htmlFor="example-text-input"
                className="col-md-12 col-form-label"
              >
                Save($)
              </label>

              <input
                className="form-control"
                type="email"
                onChange={e =>
                  setEditTheSubscribe({
                    ...editTheSubscribe,
                    discount: e.target.value,
                  })
                }
                value={editTheSubscribe?.discount}
              />
            </Col>
          </Row> */}

          <Row className="mt-3">
            <Col xs={4} sm={3} md={1}>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                onClick={updateSubscription}
              >
                Save
              </Button>{" "}
              <ToastContainer />
            </Col>
            <Col xs={3} sm={3} md={6}>
              <Link
                to="/triptrader/subscription"
                color="secondary"
                className="btn btn-secondary waves-effect"
              >
                Cancel
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
