import backend from "api/api"
import Breadcrumbs from "components/Common/Breadcrumb"
import SearchTable from "components/SearchTable/searchTable"
import React, { useEffect, useState } from "react"
import Switch from "react-switch"
import { MetaTags } from "react-meta-tags"
import servicesIcon1 from "assets/images/services-icon/01.png"
import servicesIcon2 from "assets/images/services-icon/02.png"
import servicesIcon3 from "assets/images/services-icon/03.png"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row, Card, CardBody } from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { Link } from "react-router-dom"
import { CircularProgress, Tooltip } from "@mui/material"
export default function TradeType() {
  const { traderType } = useSelector(state => state?.TraderTypeReducer)
  const activeAct = traderType.filter(e => e?.status === "active")
  const [loading, setLoading] = useState(false)

  const disabledAct = traderType.filter(e => e?.status === "disabled")
  const rows = []
  const dispatch = useDispatch()
  const getAllActivity = async () => {
    try {
      setLoading(true)
      const resp = await backend.get("/api/type")
      setLoading(false)
      dispatch({
        type: "TradeType_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }

  const changeActivityStatus = async (status, id) => {
    try {
      const value = status.props.children === "Active" ? "disabled" : "active"
      const body = {
        status: value,
      }
      const resp = await backend.put(`/api/type/${id}`, body)

      toast.success(resp?.data?.message)
      getAllActivity()
    } catch (error) {
      toast.error(error?.response?.data.message || error.message || error)
    }
  }
  const handleChangeStatus = params => {
    return (
      <>
        <Switch
          uncheckedIcon={<Offsymbol />}
          checkedIcon={<OnSymbol />}
          onColor="#02a499"
          onChange={() =>
            changeActivityStatus(params.status, params.activityId)
          }
          checked={params?.status.props.children === "Active" ? true : false}
        />
        <ToastContainer />
      </>
    )
  }
  const myAction = params => {
    return (
      <>
        <Link to={`/triptrader/tradetypeedit/${params.activityId}`}>
          <Tooltip title="Edit Trade Types">
            <EditLocationAltIcon />
          </Tooltip>
        </Link>

        <Link to={`/triptrader/tradetypedetail/${params.activityId}`}>
          <Tooltip title="View Trade Types">
            <VisibilityOutlinedIcon />
          </Tooltip>
        </Link>
      </>
    )
  }
  useEffect(() => {
    getAllActivity()
  }, [])
  const columnHead = [
    {
      title: "Sr. No#",
      field: "srNo",
    },
    {
      title: "Trade Type",
      field: "activityName",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Change Status",
      field: "ChangeStatus",
      render: handleChangeStatus,
    },
    {
      title: "Action",
      field: "action",
      render: myAction,
    },
  ]

  if (traderType) {
    traderType.map((e, i) =>
      rows.push({
        srNo: i + 1,
        activityId: e?._id,
        activityName: (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {" "}
            {e?.name}
          </span>
        ),
        status:
          e?.status === "active" ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Disable</span>
          ),
      })
    )
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Trade Type | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Trade Type"
                  breadcrumbItem="Trade Type"
                />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-15 text-uppercase mt-0">
                      Total Trade Type
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {traderType?.length}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat  text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-15 text-uppercase mt-0 ">
                      Active Trade Type
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {activeAct?.length}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat  text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h4 className="font-size-15 text-uppercase mt-0 ">
                      InActive Trade Type
                    </h4>
                    <h4 className="fw-medium font-size-24">
                      {disabledAct?.length}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {loading ? (
            <CircularProgress />
          ) : (
            <SearchTable columns={columnHead} data={rows} />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
