const initialState = {users:[]}

export default  function GetUserReducer(state = initialState, action){
  switch (action.type) {

  case "GET_USER_DATA":
    return { 
        success:true,
        users:action.payload
     }

  default:
    return state
  }
}
