import React from "react"
import {
  TableContainer,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import { format } from "date-fns"
import MetaTags from "react-meta-tags"
import { useSelector } from "react-redux"
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useParams } from "react-router-dom"
export default function ReportedUserDetail() {
  let { id } = useParams()
  const { reported } = useSelector(state => state.ReportReducer)

  const filterUser = reported.find(e => e?._id === id)
  console.log(filterUser)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reported Customer Detail| Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Customers"
                  breadcrumbItem="Reported Customer Detail"
                />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <Typography id="Pageheader"> General Information</Typography>
                <CardBody>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Posted By</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterUser?.createdAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Updated By</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterUser?.updatedAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Reported User</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Full Name</TableCell>
                          <TableCell>
                            {`${filterUser?.userId.firstName} ${filterUser?.userId.lastName} `}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Email</TableCell>
                          <TableCell>{filterUser?.userId.email} </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Link to={`/triptrader/userdetail/${filterUser?.userId._id}`}>
                    More Detail
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Report Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Reason</TableCell>
                          <TableCell>{filterUser?.reason}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {filterUser?.status === "active" ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">Resolved</span>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Report By</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Name</TableCell>
                          <TableCell>
                            {filterUser?.reportby?.firstName +
                              " " +
                              filterUser?.reportby?.lastName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Email</TableCell>
                          <TableCell>{filterUser?.reportby?.email}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
