import MetaTags from "react-meta-tags"
import React, { useState } from "react"
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap"
import { Link, useParams, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "chartist/dist/scss/chartist.scss"
import { useSelector } from "react-redux"
import backend from "api/api"
import { toast, ToastContainer } from "react-toastify"
export default function UserEdit() {
  let { id } = useParams()
  const { users } = useSelector(state => state.GetUserReducer)
  const editUser = users.find(e => e?._id == id)
  const history = useHistory()
  const [editMyUser, setEditMyUser] = useState(editUser)
  const [myidentityStatus, setMyIdentityStatus] = useState(
    editUser?.identityStatus
  )
  const [accountStatus, setAccountStatusStatus] = useState(editUser?.status)
  const [mySubscriptionStatus, setMySubscriptionStatus] = useState(
    editUser?.subscriptionStatus
  )
  const [subscriptionInformationStatus, setSubscriptionInformationStatus] =
    useState(editUser?.subscription?.status)
  const handleIdentityStatus = e => {
    setMyIdentityStatus(e.target.value)
  }
  const handleAccountStatus = e => {
    setAccountStatusStatus(e.target.value)
  }

  const handleMySubscription = e => {
    setMySubscriptionStatus(e.target.value)
  }
  const handleSubscriptionInformation = e => {
    setSubscriptionInformationStatus(e?.target.value)
  }
  const UpdateMyUser = async () => {
    try {
      const body = {
        firstName: editMyUser?.firstName,
        lastName: editMyUser?.lastName,
        email: editMyUser?.email,
        identityStatus: myidentityStatus,
        status: accountStatus,
        subscriptionStatus: mySubscriptionStatus,
        subscription: {
          status: subscriptionInformationStatus,
          amtPaid: editMyUser?.subscription?.amtPaid,
          charges: editMyUser?.subscription?.charges,
          discount: editMyUser?.subscription?.discount,
          startDate: editMyUser?.subscription?.startDate,
          endDate: editMyUser?.subscription?.endDate,
          title: editMyUser?.subscription?.title,
        },
      }

      const resp = await backend.put(`/api/user/${id}`, body)
      toast.success(resp.data.message)
      if (editUser?.subscriptionStatus === "paid") {
        setTimeout(() => history.push("/triptrader/subscribecustomer"), 1000)
      } else {
        setTimeout(() => history.push("/triptrader/freemiumcustomer"), 1000)
      }
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }
  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Customers Edit | Trip Trader</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col md={8}>
                  {/* <h6 className="page-title WebHeader">Subscribe Customers </h6> */}
                  <Breadcrumbs
                    maintitle="Trip Trader"
                    title="Customer"
                    breadcrumbItem=" Customers Edit"
                  />

                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item active"></li>
                  </ol>
                </Col>

                <Card>
                  <CardBody>
                    <h4>Personal Information</h4>
                    <Row className="mb-3">
                      <Col xs={12} sm={12} md={6}>
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          First Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={editMyUser?.firstName}
                          onChange={e =>
                            setEditMyUser({
                              ...editMyUser,
                              firstName: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Last Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={editMyUser?.lastName}
                          onChange={e =>
                            setEditMyUser({
                              ...editMyUser,
                              lastName: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} sm={12} md={6}>
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Email
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          onChange={e =>
                            setEditMyUser({
                              ...editMyUser,
                              email: e.target.value,
                            })
                          }
                          value={editMyUser?.email}
                          disabled
                        />
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <label className="col-md-12 col-form-label">
                          Status
                        </label>
                        <select
                          className="form-select"
                          value={accountStatus}
                          onChange={handleAccountStatus}
                        >
                          <option value="active">Active</option>
                          <option value="blocked">Blocked</option>
                          <option value="report">Report</option>
                        </select>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} sm={12} md={6}>
                        <label className="col-md-12 col-form-label">
                          Identity Status
                        </label>
                        <select
                          className="form-select"
                          value={myidentityStatus}
                          onChange={handleIdentityStatus}
                        >
                          <option value="pending">N/A</option>
                          <option value="verified">Verified</option>
                          <option value="notVerified">Not Verified</option>
                        </select>
                      </Col>

                      {/* <Col xs={12} sm={12} md={6}>
                        <label className="col-md-12 col-form-label">
                          Subscription Status
                        </label>
                        <select
                          className="form-select"
                          value={mySubscriptionStatus}
                          onChange={handleMySubscription}
                        >
                          <option value="freemium">Freemium</option>
                          <option value="paid">Paid</option>
                        </select>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              </Row>
            </div>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4>Subscription Status</h4>
                    {/* <label className="col-md-12 col-form-label">
                        Subscription Information Status
                      </label>
                      <select
                        className="form-select"
                        value={subscriptionInformationStatus}
                        onChange={handleSubscriptionInformation}
                      >
                        <option value="cancelled">Cancelled</option>
                        <option value="active">Active</option>
                        <option value="ended">Ended</option>
                      </select> */}
                    <span>
                      {editUser?.subscription?.status === "PremiumC"
                        ? "Cancelled Premium"
                        : editUser?.subscription?.status}
                    </span>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={4} sm={3} md={1}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  onClick={UpdateMyUser}
                >
                  Save
                </Button>{" "}
                <ToastContainer />
              </Col>
              <Col xs={3} sm={3} md={6}>
                <Link
                  to="/triptrader/subscribecustomer"
                  color="secondary"
                  className="btn btn-secondary waves-effect"
                >
                  Cancel
                </Link>{" "}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}
