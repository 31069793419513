import React from "react"
import { format } from "date-fns"
import MetaTags from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { Container, Row, Col, Button, Input, Label, Card } from "reactstrap"
import {
  Avatar,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material"
import Photo from "./assesst/Photo.png"
import bgImage from "./assesst/bgImage.png"
import profileImg from "./assesst/profileImg.jpg"
import cnicImage from "./cnicImage.jpg"
import "./DetailPage.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { styled } from "@mui/material/styles"
import { useSelector } from "react-redux"
import { Subscriptions } from "@mui/icons-material"
import "./DetailPage.css"

const ExpandMore = styled(props => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function userDetailPage() {
  const { users } = useSelector(state => state.GetUserReducer)
  let { id } = useParams()
  const findUser = users.find(e => e?._id === id)
  console.log("findUser", findUser)
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customer Detail | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            maintitle="Trip Trader"
            title="Customers"
            breadcrumbItem="Customers Detail"
          />

          <Row className="mb-3">
            <Grid container className="responsiveHandler">
              <Grid item xs={12}>
                <img src={bgImage} className="ImgWidth" />
              </Grid>
              <Grid item xs={12}>
                {findUser?.image?.length > 0 ? (
                  <img src={findUser?.image} className="PersonImg" />
                ) : (
                  <img src={profileImg} className="PersonImg" />
                )}
                <Typography id="personName">
                  {findUser?.firstName + " " + findUser?.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Paper className="detailContainerMargin" sx={{ m: 5 }}>
                  <Typography id="Pageheader">General Information</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Date of Joining
                          </TableCell>
                          <TableCell className="">
                            {format(
                              new Date(findUser?.createdAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Update Date</TableCell>
                          <TableCell className="">
                            {format(
                              new Date(findUser?.updatedAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Mobile Number
                          </TableCell>
                          <TableCell className="">
                            {/* {"+1" + findUser?.phone} */}
                            {findUser?.phone ? findUser?.phone : "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Rating</TableCell>
                          <TableCell className="">{findUser?.rating}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Reviews</TableCell>
                          <TableCell className="">
                            {findUser?.reviews}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                {findUser?.subscriptionStatus === "paid" ? (
                  <Paper sx={{ m: 5 }}>
                    <Typography id="Pageheader">
                      Subscription Information
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell id="tableHeadvalue">Title</TableCell>
                            <TableCell sx={{ textTransform: "capitalize" }}>
                              {findUser?.subscription?.title}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell id="tableHeadvalue">
                              Subscription Charges
                            </TableCell>
                            <TableCell>
                              {findUser?.subscription?.charges + " $"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell id="tableHeadvalue">Discount</TableCell>
                            <TableCell>
                              {findUser?.subscription?.discount + " $"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell id="tableHeadvalue">Status</TableCell>
                            <TableCell>
                              {/* {findUser?.subscription?.status === "active" ? (
                                <span className="badge bg-success">Active</span>
                              ) : (
                                <span
                                  className="badge bg-danger"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {findUser?.subscription?.status}
                                </span>
                              )} */}
                              <span
                               className={`badge ${
                                findUser?.subscription?.status === "freemium"
                                  ? "bg-danger"
                                  : "bg-success"
                              }`}
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {findUser?.subscription?.status === "PremiumC"
                                  ? "Cancelled Premium"
                                  : findUser?.subscription?.status}
                              </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell id="tableHeadvalue">
                              Starting Date
                            </TableCell>
                            <TableCell>
                              {format(
                                new Date(findUser?.subscription?.startDate),
                                "dd:MMM:Y, h:mm aaa"
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell id="tableHeadvalue">
                              Ending Date
                            </TableCell>
                            <TableCell>
                              {format(
                                new Date(findUser?.subscription?.endDate),
                                "dd:MMM:Y, h:mm aaa"
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell id="tableHeadvalue">ATM Paid</TableCell>
                            <TableCell>
                              {findUser?.subscription?.amtPaid + " $"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Paper className="detailContainerMargin">
                  <Typography id="Pageheader">Personal Information</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">First Name</TableCell>
                          <TableCell
                            id="TableValueCell"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {findUser?.firstName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Last Name</TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {findUser?.lastName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Email</TableCell>
                          <TableCell>{findUser?.email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Date of Birth
                          </TableCell>
                          <TableCell>
                            {format(new Date(findUser?.birthDate), "dd:MMM:Y")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            {findUser?.status == "report" ? (
                              <span className="badge bg-warning">Report</span>
                            ) : findUser?.status === "blocked" ? (
                              <span className="badge bg-danger">Blocked</span>
                            ) : (
                              <span className="badge bg-success">Active</span>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Identity Status
                          </TableCell>
                          <TableCell>
                            {findUser?.identityStatus === "pending" ? (
                              <span className="badge bg-warning">N/A</span>
                            ) : findUser?.identityStatus === "notVerified" ? (
                              <span className="badge bg-danger">
                                Not Verified
                              </span>
                            ) : (
                              <span className="badge bg-success">Verified</span>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Pin Code</TableCell>
                          <TableCell>{findUser?.pin}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Phone Country Code
                          </TableCell>
                          <TableCell>{findUser?.phoneCountryCode}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Terms Accepted
                          </TableCell>
                          <TableCell>
                            {findUser?.termsAccepted == true ? (
                              <p>Yes</p>
                            ) : (
                              <p>No</p>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Notification Enabled
                          </TableCell>
                          <TableCell>
                            {findUser?.notificationEnabled == true ? (
                              <p>Yes</p>
                            ) : (
                              <p>No</p>
                            )}
                          </TableCell>
                        </TableRow>
                        {/* <TableRow>
                          <TableCell id="tableHeadvalue">Followers</TableCell>
                          <TableCell>{findUser?.followers?.map((e)=>e?.name).join(", ")}</TableCell>
                        </TableRow> */}
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Subscription Status
                          </TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            <span
                              className={`badge ${
                                findUser?.subscription?.status === "freemium"
                                  ? "bg-danger"
                                  : "bg-success"
                              }`}
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {findUser?.subscription?.status === "PremiumC"
                                ? "Cancelled Premium"
                                : findUser?.subscription?.status}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Row>

          <Card sx={{ mt: "13px", maxWidth: 345 }}>
            <CardActions disableSpacing>
              <Typography id="tableHeadvalue">Verification Image</Typography>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    width: "60%",
                  }}
                  src={findUser?.identityProof}
                  alt="Identity Avatar"
                />
              </CardContent>
            </Collapse>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
