import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { useSelector } from "react-redux"
import backend from "api/api"
import { toast, ToastContainer } from "react-toastify"
import { Container, Row, Col, Button } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useParams, Link, useHistory } from "react-router-dom"
export default function TradeTypeEdit() {
  let { id } = useParams()
  let history = useHistory()
  const { traderType } = useSelector(state => state?.TraderTypeReducer)
  // const { activity } = useSelector(state => state?.ActivityReducer)
  const editActivity = traderType.find(e => e?._id === id)
  const [editTheActivity, setEditTheActivity] = useState(editActivity)

  const handleTheActivity = async () => {
    try {
      const body = {
        name: editTheActivity?.name,
      }
      const resp = await backend.put(`api/type/${id}`, body)
      toast.success(resp?.data?.message)
      setTimeout(() => history.push("/triptrader/tradetype"), 1000)
    } catch (error) {
      toast.error(error.response.data.message || error.message || error)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Trade Type Edit | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Trade Type"
                  breadcrumbItem="Trade Type  Edit"
                />

                <ol className="breadcrumb m-0">
                  <li className="lbreadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                >
                  Trade Type Name
                </label>

                <input
                  className="form-control"
                  type="text"
                  value={editTheActivity.name}
                  onChange={e =>
                    setEditTheActivity({
                      ...editTheActivity,
                      name: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={4} sm={3} md={1}>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                onClick={handleTheActivity}
              >
                Save
              </Button>{" "}
              <ToastContainer />
            </Col>
            <Col xs={3} sm={3} md={6}>
              <Link
                to="/triptrader/tradetype"
                color="secondary"
                className="btn btn-secondary waves-effect"
              >
                Cancel
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
