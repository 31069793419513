import React, { Component } from "react"
import { Line } from "react-chartjs-2"

class LineChart extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "Paid Customer",
          fill: true,
          lineTension: 0.5,
          backgroundColor: "#c7c2c2",
          borderColor: "#5e5a5a",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#5e5a5a",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "white",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: this.props.customerStats?.map(e => e?.paidUsers),
        },
        {
          label: "Freemium Customer",
          fill: true,
          lineTension: 0.5,
          backgroundColor: "rgba(235, 239, 242, 0.2)",
          borderColor: "#af9a9a",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#ebeff2",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#ebeff2",
          pointHoverBorderColor: "#eef0f2",
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: this.props.customerStats?.map(e => e?.freemiumUsers),
        },
      ],
    }
    var option = {
      scales: {
        yAxes: [
          {
            ticks: {
              max: 100,
              min: 0,
              stepSize: 10,
            },
          },
        ],
      },
    }
    return (
      <React.Fragment>
        <Line width={600} height={245} data={data} options={option} />
      </React.Fragment>
    )
  }
}

export default LineChart
