import backend from "api/api"
import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useState, useEffect } from "react"
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row, Button } from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
export default function NewSpecies() {
  const [state, setState] = useState({
    speciesname: "",
    TradeType: "Camping",
    categoryies: "Bears",
  })
  const { Category } = useSelector(state => state?.CategoryReducer)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { traderType } = useSelector(state => state.TraderTypeReducer)
  const history = useHistory()
  const handleSpecies = e => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const postSpecies = async () => {
    try {
      const selectedType = traderType?.find(e => e?.name === state?.TradeType);
      const selectedCategory = Category?.find(e => e?.name === state?.categoryies);
  
      if (!state.speciesname) {
        toast.error("Species Name is required");
        return;
      }
      if (!state.TradeType || !selectedType) {
        toast.error("Trade Type is Required");
        return;
      }
      if (!state.categoryies || !selectedCategory) {
        toast.error("Category is Required");
        return;
      }
  
      const body = {
        name: state.speciesname,
        status: "active",
        type: selectedType._id,
        category: selectedCategory._id,
      };
  
      console.log(body); 

  
      const resp = await backend.post("/api/species", body);
      toast.success(resp?.data?.message);
      setTimeout(() => history.push("/triptrader/species"), 1000);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error);
    }
  };
  
  const getAllCategory = async () => {
    try {
      const resp = await backend.get("/api/category")
      console.log(resp)
      dispatch({
        type: "CATEGORY_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }
  const getAllTradeType = async () => {
    try {
      setLoading(true)
      const resp = await backend.get("/api/type")
      setLoading(false)
      dispatch({
        type: "TradeType_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }

  useEffect(() => {
    getAllTradeType()
    getAllCategory()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>New Species | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Species"
                  breadcrumbItem="New Species"
                />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                >
                  Species Name
                </label>

                <input
                  className="form-control"
                  type="text"
                  name="speciesname"
                  onChange={handleSpecies}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label className="col-md-12 col-form-label">Trader Type</label>

                <select
                  className="form-select"
                  name="TradeType"
                  onChange={handleSpecies}
                >
                  {traderType?.map(e => (
                    <>
                      <option value={e?.name}>{e?.name}</option>
                    </>
                  ))}
                </select>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label className="col-md-12 col-form-label">Category</label>

                <select
                  className="form-select"
                  name="categoryies"
                  onChange={handleSpecies}
                >
                  {Category?.map(e => (
                    <>
                      <option value={e?.name}>{e?.name}</option>
                    </>
                  ))}
                </select>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "1rem",
              }}
            >
              <Col xs={4} sm={3} md={1}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  onClick={postSpecies}
                >
                  Save
                </Button>{" "}
                <ToastContainer />
              </Col>
              <Col xs={3} sm={3} md={6}>
                <Link
                  to="/triptrader/species"
                  color="secondary"
                  className="btn btn-secondary waves-effect"
                >
                  Cancel
                </Link>{" "}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
