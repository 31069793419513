import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import DashboardIcon from "@mui/icons-material/Dashboard"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave"
import GroupIcon from "@mui/icons-material/Group"
import RateReviewIcon from "@mui/icons-material/RateReview"
import SimpleBar from "simplebar-react"
import SubscriptionsIcon from "@mui/icons-material/Subscriptions"
import SettingsIcon from "@mui/icons-material/Settings"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import LocalActivityIcon from "@mui/icons-material/LocalActivity"
import { withTranslation } from "react-i18next"
import QrCodeIcon from "@mui/icons-material/QrCode"
import { logoutUser } from "store/actions"
import { useDispatch } from "react-redux"
import CorporateFareIcon from "@mui/icons-material/CorporateFare"
const SidebarContent = props => {
  const ref = useRef()

  const dispatch = useDispatch()
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/triptrader/dashboard">
                <DashboardIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Dashboard")}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/triptrader/customer"
                className="has-arrow waves-effect"
              >
                <GroupIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Customers")}
                </span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/triptrader/subscribecustomer">
                    {props.t("Subscribe Customers")}
                  </Link>
                </li>
                <li>
                  <Link to="/triptrader/freemiumcustomer">
                    {props.t("Freemium Customers")}{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/triptrader/reportedcustomers">
                    {props.t("Reported Customers")}{" "}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="" className="has-arrow waves-effect">
                <AdminPanelSettingsIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Admins")}
                </span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/triptrader/admin">{props.t("Admins")}</Link>
                </li>
                <li>
                  <Link to="/triptrader/newadmin">
                    {props.t("New Admins")}{" "}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/triptrader/trips">
                <TimeToLeaveIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Trips")}
                </span>
              </Link>
            </li>

            <li>
              <Link to="/triptrader/setting">
                <SettingsIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Settings")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="" className="has-arrow waves-effect">
                <LocalActivityIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Trade Type")}
                </span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/triptrader/tradetype">
                    {props.t("Trade Type")}
                  </Link>
                </li>
                <li>
                  <Link to="/triptrader/newtradetype">
                    {props.t("New Trade Type")}{" "}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="" className="has-arrow waves-effect">
                <LocalActivityIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Catagory")}
                </span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/triptrader/catagory">{props.t("Catagory")}</Link>
                </li>
                <li>
                  <Link to="/triptrader/newCategory">
                    {props.t("New Catagory")}{" "}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="" className="has-arrow waves-effect">
                <CorporateFareIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("State")}
                </span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/triptrader/state">{props.t("State")}</Link>
                </li>
                <li>
                  <Link to="/triptrader/newstate">{props.t("New State")} </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="" className="has-arrow waves-effect">
                <GiModernCity
                  style={{
                    fontSize: "25px",
                  }}
                />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("City")}
                </span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/triptrader/city">{props.t("City")}</Link>
                </li>
                <li>
                  <Link to="/triptrader/newcity">{props.t("New City")} </Link>
                </li>
              </ul>
            </li> */}

            <li>
              <Link to="" className="has-arrow waves-effect">
                <LocalActivityIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Species")}
                </span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/triptrader/species">{props.t("Species")}</Link>
                </li>
                <li>
                  <Link to="/triptrader/newspecies">
                    {props.t("New Species")}{" "}
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="" className="has-arrow waves-effect">
                <SubscriptionsIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Subscription")}
                </span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/triptrader/subscription">
                    {props.t("Subscription")}
                  </Link>
                </li>
                <li>
                  <Link to="/triptrader/newsubscription">
                    {props.t("New Subscription")}{" "}
                  </Link>
                </li>
              </ul>
            </li> */}

            <li>
              <Link to="/triptrader/subscription">
                <SubscriptionsIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Subscription")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/triptrader/review">
                <RateReviewIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Manage Reviews")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="" className="has-arrow waves-effect">
                <QrCodeIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Promo Code")}
                </span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/triptrader/promocode">
                    {props.t("Promo Code")}
                  </Link>
                </li>
                <li>
                  <Link to="/triptrader/newpromocode">
                    {props.t("New Promo Code")}{" "}
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li
              style={{
                marginTop: "16rem",
                // position: "fixed",
              }}
            >
              <Link to="/triptrader/customer" onClick={logout}>
                <LogoutIcon />
                <span
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {props.t("Log Out")}
                </span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
