const initialState = { Category: [] }

export default function CategoryReducer(state = initialState, action) {
  switch (action.type) {
    case "CATEGORY_SUCCESS":
      return { ...state, Category: action.payload }

    default:
      return state
  }
}
