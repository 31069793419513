import React, { useState } from "react"
import { Col, Container, Row, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import backend from "api/api"
import SearchTable from "components/SearchTable/searchTable"
import { useEffect } from "react"
import servicesIcon1 from "assets/images/services-icon/01.png"
import servicesIcon2 from "assets/images/services-icon/02.png"
import servicesIcon3 from "assets/images/services-icon/03.png"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { useDispatch, useSelector } from "react-redux"
import { CircularProgress, Tooltip } from "@material-ui/core"
export default function Trip() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const rows = []
  const { trip } = useSelector(state => state.TripReducer)
  const activeTrips = trip.filter(e => e?.status == "active")
  const suspendedTrips = trip.filter(e => e?.status == "suspended")
  const handleMyTrip = async () => {
    try {
      setLoading(true)
      const resp = await backend.get("/api/trip")
      setLoading(false)
      dispatch({
        type: "TRIP_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }

  if (trip) {
    trip.map((e, i) =>
      rows.push({
        srNo: i + 1,
        tripId: e?._id,
        activityName: e?.tradeType,
        hostName: e?.hostId?.firstName,
        status:
          e?.status === "active" ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Suspended</span>
          ),
        duration: e?.duration?.value + " " + e?.duration?.title,
        location: e?.location?.name,
      })
    )
  }
  const myAction = params => {
    return (
      <>
        <Link to={`/triptrader/tripedit/${params.tripId}`}>
          <Tooltip title="Edit Trips">
            <EditLocationAltIcon />
          </Tooltip>
        </Link>

        <Link to={`/triptrader/tripdetail/${params.tripId}`}>
          <Tooltip title="View Trips">
            <VisibilityOutlinedIcon />
          </Tooltip>
        </Link>
      </>
    )
  }
  const columns = [
    {
      title: "Sr No#",
      field: "srNo",
    },
    {
      title: "Trade Type",
      field: "activityName",
    },
    {
      title: "Host Name",
      field: "hostName",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Duration",
      field: "duration",
    },
    // {
    //   title: "Location",
    //   field: "location",
    // },
    {
      title: "Actions",
      field: "actions",
      render: myAction,
    },
  ]

  useEffect(() => {
    handleMyTrip()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Trips | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Trips"
                  breadcrumbItem="Trips"
                />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col xl={4} md={6}>
                <Card
                  className="mini-stat text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon1} alt="" />
                      </div>
                      <h5 className="font-size-15 text-uppercase mt-0">
                        Total Trips
                      </h5>
                      <h4 className="fw-medium font-size-24">{trip?.length}</h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4} md={6}>
                <Card
                  className="mini-stat  text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon2} alt="" />
                      </div>
                      <h5 className="font-size-15 text-uppercase mt-0 ">
                        Active Trips
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {activeTrips?.length}{" "}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4} md={6}>
                <Card
                  className="mini-stat  text-white"
                  style={{ backgroundColor: "#283c2e" }}
                >
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon3} alt="" />
                      </div>
                      <h4 className="font-size-15 text-uppercase mt-0 ">
                        Suspended Trips
                      </h4>
                      <h4 className="fw-medium font-size-24">
                        {suspendedTrips?.length}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {loading ? (
            <CircularProgress />
          ) : (
            <SearchTable columns={columns} data={rows} />
          )}
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}
