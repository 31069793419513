import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  UPDATE_AUTH,
} from "./actionTypes"

export const updateAuth = isAuth => {
  return {
    type: UPDATE_AUTH,
    payload: { isAuth },
  }
}
export const loginUser = (user, history, toast) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, toast },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = toast => {
  return {
    type: LOGOUT_USER,
    payload: {},
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
