import backend from "api/api"
import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useState, useEffect } from "react"
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row, Button } from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
export default function NewSubscription() {
  const [state, setState] = useState({
    type: "",
    charges: "",
    discount: "",
  })
  console.log(state)
  const { Category } = useSelector(state => state?.CategoryReducer)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { traderType } = useSelector(state => state.TraderTypeReducer)
  const history = useHistory()
  const handleSubscription = e => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const postSpecies = async () => {
    try {
      const body = {
        type: state?.type,
        charges: state?.charges,
        status: "active",
        discount: state?.discount,
      }
      console.log(body)
      if (!state.type || state.type == "") {
        toast.error("Type is required")
      } else if (!state.charges || state.charges == "") {
        toast.error("Charges is Required")
      } else if (!state.discount || state.discount == "") {
        toast.error("Discount is Required")
      } else {
        const resp = await backend.post("/api/plan", body)
        toast.success(resp?.data?.message)
        setTimeout(() => history.push("/triptrader/subscription"), 1000)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>New Subscription | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Subscription"
                  breadcrumbItem="New Subscription"
                />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <label className="col-md-12 col-form-label">Type</label>
                <select
                  className="form-select"
                  name="type"
                  onChange={handleSubscription}
                >
                  <option value={"annual"}>Annual</option>
                  <option value={"monthly"}>Monthly</option>
                  <option value={"weekly"}>Weekly</option>
                </select>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                >
                  Charges
                </label>

                <input
                  className="form-control"
                  type="number"
                  name="charges"
                  onChange={handleSubscription}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                >
                  Discount
                </label>

                <input
                  className="form-control"
                  type="number"
                  name="discount"
                  onChange={handleSubscription}
                />
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "1rem",
              }}
            >
              <Col xs={4} sm={3} md={1}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  onClick={postSpecies}
                >
                  Save
                </Button>{" "}
                <ToastContainer />
              </Col>
              <Col xs={3} sm={3} md={6}>
                <Link
                  to="/triptrader/subscription"
                  color="secondary"
                  className="btn btn-secondary waves-effect"
                >
                  Cancel
                </Link>{" "}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
