import MetaTags from "react-meta-tags"
import React, { useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useSelector } from "react-redux"
import {
  Col,
  Row,
  Container,
  Label,
  InputGroup,
  Button,
  FormGroup,
} from "reactstrap"
import { useParams, Link, useHistory } from "react-router-dom"
import "flatpickr/dist/themes/material_green.css"
import Flatpickr from "react-flatpickr"
import { ToastContainer, toast } from "react-toastify"
import backend from "api/api"

export default function PromoCodeEdit() {
  const { id } = useParams()
  const todayDate = new Date()
  const history = useHistory()
  const { code } = useSelector(state => state.PromoCodeReducer)
  const editCode = code?.data?.find(e => e?.id === id)
  const [editThePromo, setEditThePromo] = useState(editCode)
  const [percentageOff, setPercentageOff] = useState(
    editThePromo?.coupon?.percent_off
  )
  const [durationMonth, setDurationMonth] = useState(
    editThePromo?.coupon?.duration_in_months
  )
  const [duration, setDuration] = useState(editThePromo?.coupon?.duration)
  const [amountOff, setAmountOff] = useState(
    editThePromo?.coupon?.amount_off == "null" && 0
  )
  const [couponCode, setCouponCode] = useState(editThePromo?.code)
  const [maxRedemption, setMaxRedemtion] = useState(
    editThePromo?.coupon?.max_redemptions
  )
  const [discountOff, setDiscountSet] = useState("Percentage")
  console.log(editThePromo)
  const converttoSeconds = dates => {
    const date = new Date(dates)
    const timestamp = Math.floor(date.getTime() / 1000)
    return timestamp
  }
  console.log("line32", editThePromo)
  const editTheCode = async () => {
    try {
      console.log("line 34", editThePromo)
      const createdDate = converttoSeconds(editThePromo?.created)
      const expiryDate = converttoSeconds(editThePromo?.expires_at)
      const body = {
        couponId: editThePromo?.coupon?.id,
        code: couponCode,
        coupon: {
          percent_off: percentageOff,
          amount_off: amountOff,
          duration,
          duration_in_months: durationMonth,
          max_redemptions: maxRedemption,
        },
        created: createdDate,
        expires_at: expiryDate,
      }
      console.log("line 46", body)
      const resp = await backend.patch(
        `/api/code/updateallpromocode/${id}`,
        body
      )
      toast.success(resp.data.message)
      setTimeout(() => history.push("/triptrader/promocode"), 1000)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }
  const timeStampTime = timestamp => {
    const date = new Date(timestamp * 1000)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const seconds = String(date.getSeconds()).padStart(2, "0")

    const amPm = hours >= 12 ? "PM" : "AM"
    const formattedHours = hours % 12 || 12

    const formattedDate = `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${amPm}`
    return formattedDate
  }
  const createdAtTime = timeStampTime(editCode?.coupon?.created)
  const ExpiryAtTime = timeStampTime(editCode?.expires_at)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Promo Code Edit| Trip Traders</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Promo Code"
                  breadcrumbItem="Promo Code Edit"
                />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                >
                  Code
                </label>
                <input
                  className="form-control"
                  type="text"
                  // name="code"
                  value={couponCode}
                  onChange={e => setCouponCode(e?.target?.value)}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                >
                  Discount
                </label>
                <select
                  className="form-select"
                  name="discount"
                  value={discountOff}
                  onChange={e => {
                    setDiscountSet(e?.target.value)
                  }}
                  defaultValue={"Percentage"}
                >
                  <option value="Percentage">Percentage</option>
                  <option value="Amount">Amount</option>
                </select>
              </Col>
            </Row>
            <Row>
              {discountOff === "Percentage" ? (
                <Col xs={12} sm={12} md={6}>
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Percentage Off
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    value={percentageOff}
                    onChange={e => {
                      setPercentageOff(e?.target?.value)
                    }}
                    // onChange={handleStateChange}
                  />
                </Col>
              ) : (
                <Col xs={12} sm={12} md={6}>
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Amount Off
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="amount_off"
                    value={amountOff}
                    onChange={e => {
                      setAmountOff(e?.target?.value)
                    }}
                  />
                </Col>
              )}
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-2 col-form-label"
                >
                  Duration
                </label>
                <select
                  className="form-select"
                  value={duration}
                  name="duration"
                  onChange={e => {
                    setDuration(e?.target?.value)
                  }}
                >
                  <option value="once">once</option>
                  <option value="repeating">Repeating</option>
                  <option value="forever">Forever</option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-2 col-form-label"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Duration Months
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="durationMonth"
                  value={durationMonth}
                  onChange={e => {
                    setDurationMonth(e?.target?.value)
                  }}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-2 col-form-label"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Max Redemptions
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="max_redemptions"
                  value={maxRedemption}
                  onChange={e => {
                    setMaxRedemtion(e?.target?.value)
                  }}
                />
              </Col>

              {/* <Col xs={12} sm={12} md={6}> */}
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <FormGroup className="mb-4 ">
                  <Label>Start Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="dd M,yyyy"
                      options={{
                        // minDate: "today",
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        enableTime: true,
                      }}
                      value={createdAtTime}
                      onChange={e =>
                        setEditThePromo({ ...editThePromo.coupon, created: e })
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <FormGroup className="mb-4 ">
                  <Label>End Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="dd M,yyyy"
                      options={{
                        altInput: true,
                        // altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                      }}
                      value={ExpiryAtTime}
                      onChange={e =>
                        setEditThePromo({ ...editThePromo, expires_at: e })
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={4} sm={3} md={1}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  onClick={editTheCode}
                >
                  Save
                </Button>
                <ToastContainer />
              </Col>
              <Col xs={3} sm={3} md={6}>
                <Link
                  to="/triptrader/promocode"
                  color="secondary"
                  className="btn btn-secondary waves-effect"
                >
                  Cancel
                </Link>{" "}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
