const initialState = { tripStates: [] }

export default function TripStatsReducer(state = initialState, action) {
  switch (action.type) {
    case "TRIPSTATS_SUCCESS":
      return { ...state, tripStates: action.payload }
    default:
      return state
  }
}
