import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import rootReducer from "./reducers"
import rootSaga from "./sagas"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
import { createMigrate } from "redux-persist"

const migrations = {
  1: state => ({ ...state, extraProp: true }),
  2: state => ({ ...state, extraProp: undefined, extraPropNew: true }),
}
const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  migrate: createMigrate(migrations),
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(rootSaga)

export default store
