import backend from "api/api"
import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row, Card, CardBody } from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import SearchTable from "components/SearchTable/searchTable"
import { useDispatch, useSelector } from "react-redux"
import Switch from "react-switch"
import servicesIcon1 from "assets/images/services-icon/01.png"
import servicesIcon2 from "assets/images/services-icon/02.png"
import servicesIcon3 from "assets/images/services-icon/03.png"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { Link } from "react-router-dom"
import { CircularProgress, Tooltip } from "@mui/material"
export default function Species() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const rows = []
  const { species } = useSelector(state => state.SpeciesReducers)
  const activeSpecies = species.filter(e => e?.status === "active")
  const disabledSpecies = species.filter(e => e?.status === "disabled")
  const getAllSpecies = async () => {
    try {
      setLoading(true)
      const resp = await backend.get("/api/species")
      setLoading(false)
      dispatch({
        type: "SPECIES_SUCCESS",
        payload: resp.data.data,
      })
    } catch (error) {
      toast.error(error.response?.data.message || error?.message || error)
    }
  }

  const myAction = params => {
    return (
      <>
        <Link to={`/triptrader/speciesedit/${params.speciesId}`}>
          <Tooltip title="Edit Species">
            <EditLocationAltIcon />
          </Tooltip>
        </Link>

        <Link to={`/triptrader/speciesdetail/${params.speciesId}`}>
          <Tooltip title="View Species">
            <VisibilityOutlinedIcon />
          </Tooltip>
        </Link>
      </>
    )
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }
  if (species) {
    species.map((e, i) =>
      rows.push({
        srNo: i + 1,
        speciesId: e?._id,
        name: e?.name,
        tradeType: (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {e?.type?.name}
          </span>
        ),
        category: (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {e?.category?.name}
          </span>
        ),
        status:
          e?.status === "active" ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Disable</span>
          ),
      })
    )
  }
  const changeSpeciesStatus = async (status, id) => {
    try {
      const value = status.props.children === "Active" ? "disabled" : "active"
      const body = {
        status: value,
      }
      const resp = await backend.put(`/api/species/${id}`, body)
      toast.success(resp.data.message)
      getAllSpecies()
    } catch (error) {
      toast.error(error.response?.data.message || error?.message | error)
    }
  }
  const changeAccountStatus = params => {
    return (
      <>
        <Switch
          uncheckedIcon={<Offsymbol />}
          checkedIcon={<OnSymbol />}
          onColor="#02a499"
          onChange={() => changeSpeciesStatus(params.status, params.speciesId)}
          checked={params?.status.props.children === "Active" ? true : false}
        />
        <ToastContainer />
      </>
    )
  }
  const columns = [
    {
      title: "Sr. No#",
      field: "srNo",
    },
    {
      title: "Category",
      field: "category",
    },
    {
      title: "Species Name",
      field: "name",
    },
    {
      title: "Trade Type",
      field: "tradeType",
    },

    {
      title: "Status",
      field: "status",
    },

    {
      title: "Change Status",
      field: "chnageStatus",
      render: changeAccountStatus,
    },
    {
      title: "Actions",
      field: "Actions",
      render: myAction,
    },
  ]

  useEffect(() => {
    getAllSpecies()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Species | Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Breadcrumbs
                  maintitle="Trip Trader"
                  title="Species"
                  breadcrumbItem="Species"
                />

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0">
                      Total Species
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {species?.length}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat  text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-14 text-uppercase mt-0 ">
                      Active Species
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {activeSpecies?.length}{" "}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6}>
              <Card
                className="mini-stat  text-white"
                style={{ backgroundColor: "#283c2e" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h4 className="font-size-14 text-uppercase mt-0 ">
                      InActive Species
                    </h4>
                    <h4 className="fw-medium font-size-24">
                      {disabledSpecies?.length}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {loading ? (
            <CircularProgress />
          ) : (
            <SearchTable columns={columns} data={rows} />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
