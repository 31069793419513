import React, { useState } from "react"
import { format } from "date-fns"
import MetaTags from "react-meta-tags"
import { Link, useParams, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { styled } from "@mui/material/styles"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Container, Row, Col, Button, CardBody } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material"
import backend from "api/api"
import { toast, ToastContainer } from "react-toastify"
const ExpandMore = styled(props => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))
export default function ReviewDetail() {
  const { review } = useSelector(state => state.ReviewReducer)
  const { id } = useParams()
  const [expanded, setExpanded] = React.useState(false)
  const dispatch = useDispatch()
  const getAllReview = async () => {
    const resp = await backend.get("/api/review")
    dispatch({
      type: "REVIEW_SUCCESS",
      payload: resp.data.data,
    })
  }
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const filterReview = review?.find(e => e?._id === id)
  const handleIsFeatured = async () => {
    try {
      const value = filterReview?.isFeatured == true ? false : true
      const body = {
        isFeatured: value,
        featuredDate: new Date(),
      }
      const resp = await backend.put(`/api/review/${id}`, body)
      toast.success(resp.data?.message)
      getAllReview()
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Manage Review| Trip Trader</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            maintitle="Trip Trader"
            title="Manage Review"
            breadcrumbItem="Review Detail"
          />
          <Row>
            <Col xs={12}>
              <Button
                onClick={handleIsFeatured}
                style={{
                  background: "#3b71ca",
                  color: "white",
                  position: "absolute",
                  right: "20px",
                }}
              >
                {filterReview?.isFeatured === true
                  ? "I am Featured Reviews"
                  : "I am not Featured Reviews"}
              </Button>
              <ToastContainer />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Featured Information</Typography>
                  <Table>
                    <TableRow>
                      <TableCell id="tableHeadvalue">Featured Trip</TableCell>
                      <TableCell>
                        {filterReview?.isFeatured === true ? "True" : "False"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell id="tableHeadvalue">Date</TableCell>
                      <TableCell>
                        {" "}
                        {format(
                          new Date(filterReview?.featuredDate),
                          "dd:MMM: Y,h:mm aaa"
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                </CardBody>
              </Card>

              <Card sx={{ mt: 4 }}>
                <CardBody>
                  <Typography id="Pageheader">Host Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Name</TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {`${filterReview?.hostId?.firstName} ${filterReview?.hostId?.lastName}`}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Email</TableCell>
                          <TableCell>{filterReview?.hostId?.email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Subscription Status
                          </TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {filterReview?.hostId?.subscriptionStatus}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            {filterReview?.hostId?.status == "report" ? (
                              <span className="badge bg-warning">Report</span>
                            ) : filterReview?.hostId?.status === "blocked" ? (
                              <span className="badge bg-danger">Blocked</span>
                            ) : (
                              <span className="badge bg-success">Active</span>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Guest Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Name</TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {`${filterReview?.guestId?.firstName} ${filterReview?.guestId?.lastName}`}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Email</TableCell>
                          <TableCell>{filterReview?.guestId?.email}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Subscription Status
                          </TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {filterReview?.guestId?.subscriptionStatus}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">
                            Guest Rating
                          </TableCell>
                          <TableCell>{filterReview?.guestRating}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            {filterReview?.guestId?.status == "report" ? (
                              <span className="badge bg-warning">Report</span>
                            ) : filterReview?.guestId?.status === "blocked" ? (
                              <span className="badge bg-danger">Blocked</span>
                            ) : (
                              <span className="badge bg-success">Active</span>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Typography id="Pageheader">Review Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Posted At</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterReview?.createdAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Updated At</TableCell>
                          <TableCell>
                            {format(
                              new Date(filterReview?.updatedAt),
                              "dd:MMM:Y, h:mm aaa"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Status</TableCell>
                          <TableCell>
                            {filterReview?.status === "notGiven" ? (
                              <span className="badge bg-warning">
                                Not Given
                              </span>
                            ) : filterReview?.status === "active" ||
                              filterReview?.status === "approved" ? (
                              <span
                                style={{
                                  textTransform: "capitalize",
                                }}
                                className="badge bg-success"
                              >
                                {filterReview?.status}
                              </span>
                            ) : (
                              <span
                                style={{
                                  textTransform: "capitalize",
                                }}
                                className="badge bg-danger"
                              >
                                {filterReview?.status}
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                        {filterReview?.disputeOpenDate?.length > 0 ? (
                          <TableRow>
                            <TableCell id="tableHeadvalue">
                              Dispute Open Date
                            </TableCell>
                            <TableCell>
                              format( new Date(filterReview?.disputeOpenDate),
                              "dd:MMM:Y, h:mm aaa" )
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card className="mt-5">
                <CardBody>
                  <Typography id="Pageheader">Trip Detail</Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell id="tableHeadvalue">Trip Title</TableCell>
                          <TableCell>
                            {filterReview?.tripDetails?.title}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {filterReview?.messages?.length > 0 ? (
            <Row className="mt-5">
              <Card sx={{ maxWidth: "100%" }}>
                <CardActions disableSpacing>
                  <Typography id="Pageheader">Messages</Typography>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Col xs={8} sm={8} md={8}>
                      {filterReview?.messages?.map(e => (
                        <>
                          <span style={{}}>{e?.role}</span>
                          <p>{e?.message}</p>
                        </>
                      ))}
                    </Col>
                  </CardContent>
                </Collapse>
              </Card>
            </Row>
          ) : (
            ""
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
