const initialState = { subscribe: [] }

export default function SubscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case "SUBSCRIBE_SUCCESS":
      return {
        success: true,
        subscribe: action.payload,
      }

    default:
      return state
  }
}
