import React from "react"
//<--------- TripTrader pages------>
import { Redirect } from "react-router-dom"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Login2 from "../pages/AuthenticationInner/Login2"
import Dashboard from "../pages/Dashboard/index"
import FreemiumUser from "pages/Users/freemiumUser"
import SubscribeCustomer from "pages/Users/Users"
import Admin from "pages/AuthenticationInner/Admin/admin"
import UserDetailPage from "pages/detailPage/userDetailPage"
import UserEdit from "pages/EditPage/UserEdit"
import NewAdmin from "pages/AuthenticationInner/Admin/NewAdmin"
import Trip from "pages/trip/trip"
import TripDetail from "pages/detailPage/tripdetail"
import tripEdit from "pages/EditPage/tripEdit"
import ChangePassword from "pages/changePassword/changePassword"
import TradeType from "pages/tradeType/TradeType"
import TradeTypeDetail from "pages/detailPage/tradeTypeDetail"
import TradeTypeEdit from "pages/EditPage/tradeTypeEdit"
import NewTradeType from "pages/tradeType/newTradeType"
import Species from "pages/species/species"
import NewSpecies from "pages/species/newSpecies"
import SpeciesDetail from "pages/detailPage/speciesDetail"
import SpeciesEdit from "pages/EditPage/speciesEdit"
import Subcription from "pages/subscription/subcription"
import SubscriptionDetail from "pages/detailPage/subscriptionDetail"
import SubscriptionEdit from "pages/EditPage/subcriptionEdit"
import PromoCode from "pages/promoCode/promoCode"
import NewPromoCode from "pages/promoCode/newPromoCode"
import PromoCodeDetail from "pages/detailPage/promoCodeDetail"
import PromoCodeEdit from "pages/EditPage/promoCodeEdit"
import Setting from "pages/setting/setting"
import Review from "pages/review/review"
import ReviewEdit from "pages/EditPage/ReviewEdit"
import ReviewDetail from "pages/detailPage/ReviewDetail"
import State from "pages/location/State"
import NewState from "pages/location/newState"

import StateDetail from "pages/detailPage/StateDetail"
import StateEdit from "pages/EditPage/stateEdit"
import NoFoundpage from "pages/noFoundpage/noFoundpage"
import ReportedCustomers from "pages/Users/ReportedUsers"
import ReportedUserDetail from "pages/detailPage/reportedUserDetail"
import Catagory from "pages/Catagory/catagory"
import CategoryDetail from "pages/detailPage/categoryDetail"
import CategoryEdit from "pages/EditPage/CategoryEdit"
import NewCategory from "pages/Catagory/NewCategory"
import NewSubscription from "pages/subscription/newSubscription"
// <--------TripTrader -------->
const userRoutes = [
  { path: "/", component: Dashboard },
  { path: "/triptrader/dashboard", component: Dashboard },
  { path: "/triptrader/reportedcustomers", component: ReportedCustomers },
  { path: "/triptrader/reportedcustomers/:id", component: ReportedUserDetail },
  { path: "/triptrader/catagory", component: Catagory },
  { path: "/triptrader/categorydetail/:id", component: CategoryDetail },
  { path: "/triptrader/categoryedit/:id", component: CategoryEdit },
  { path: "/triptrader/newcategory", component: NewCategory },

  { path: "/triptrader/subscribecustomer", component: SubscribeCustomer },
  { path: "/triptrader/useredit/:id", component: UserEdit },
  { path: "/triptrader/freemiumcustomer", component: FreemiumUser },
  { path: "/triptrader/userdetail/:id", component: UserDetailPage },
  { path: "/triptrader/admin", component: Admin },
  { path: "/triptrader/tradetype", component: TradeType },
  { path: "/triptrader/state", component: State },
  { path: "/triptrader/statedetail/:id", component: StateDetail },
  { path: "/triptrader/stateedit/:id", component: StateEdit },
  { path: "/triptrader/newadmin", component: NewAdmin },
  { path: "/triptrader/trips", component: Trip },
  { path: "/triptrader/tripdetail/:id", component: TripDetail },
  { path: "/triptrader/tripedit/:id", component: tripEdit },
  { path: "/triptrader/tradetypedetail/:id", component: TradeTypeDetail },
  { path: "/triptrader/tradetypeedit/:id", component: TradeTypeEdit },
  { path: "/triptrader/newtradetype", component: NewTradeType },
  { path: "/triptrader/species", component: Species },
  { path: "/triptrader/newspecies", component: NewSpecies },
  { path: "/triptrader/speciesdetail/:id", component: SpeciesDetail },
  { path: "/triptrader/speciesedit/:id", component: SpeciesEdit },
  { path: "/triptrader/subscription", component: Subcription },
  { path: "/triptrader/subscriptiondetail/:id", component: SubscriptionDetail },
  { path: "/triptrader/subscriptionedit/:id", component: SubscriptionEdit },
  { path: "/triptrader/promocode", component: PromoCode },
  { path: "/triptrader/newpromocode", component: NewPromoCode },
  { path: "/triptrader/promodetail/:id", component: PromoCodeDetail },
  { path: "/triptrader/promoedit/:id", component: PromoCodeEdit },
  { path: "/triptrader/setting", component: Setting },
  { path: "/triptrader/review", component: Review },
  { path: "/triptrader/reviewedit/:id", component: ReviewEdit },
  { path: "/triptrader/reviewdetail/:id", component: ReviewDetail },
  { path: "/triptrader/newstate", component: NewState },
  { path: "/triptrader/changepassword", component: ChangePassword },
  { path: "/triptrader/newsubscription", component: NewSubscription },

  // { path: "*", component: Dashboard },
  // <--------TripTrader -------->

  {
    path: "*",
    component: () => <Redirect to="/triptrader/dashboard" />,
  },
  // this route should be at the end of all other routes
  {
    path: "/",
    component: () => <Redirect to="/triptrader/dashboard" />,
  },
]

const authRoutes = [
  { path: "/login", component: Login2 },
  { path: "/", component: Login2 },
  { path: "/forgotpassword", component: ForgetPwd },
  { path: "/triptrader/login", component: Login2 },
  { path: "*", component: NoFoundpage },
  {
    path: "/",

    component: () => <Redirect to="/login" />,
  },
]

export { userRoutes, authRoutes }
